import Experience from "./Experience";
import Education from "./Education";

const Resume = () => {
  

  return (
    <div>
      {/* <div className="align-middle text-center font-poppins text-4xl text-[#333333]">
        Resume
      </div> */}
      <Experience />
      <Education />
    </div>
  );
};

export default Resume;
